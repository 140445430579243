var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "19",
        height: "20",
        viewBox: "0 0 19 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M17.1 2.98886L18.7707 1.3181C18.9206 1.16821 18.9956 0.987471 18.9956 0.771462C18.9956 0.559861 18.9206 0.37471 18.7707 0.224826C18.7002 0.154292 18.6164 0.0969838 18.5195 0.0573086C18.4269 0.0176334 18.3299 0 18.2285 0C18.1271 0 18.0257 0.0176334 17.9332 0.0573086C17.8406 0.0969838 17.7568 0.154292 17.6819 0.224826L16.0111 1.89559C14.5123 0.745012 12.7357 0.634803 10.6814 1.56937C9.59696 2.06311 8.65798 2.70673 7.86447 3.50023C7.71459 3.65012 7.63965 3.83086 7.63965 4.04687C7.63965 4.25847 7.71459 4.44362 7.86447 4.5935L14.4109 11.1399C14.5608 11.2898 14.7415 11.3647 14.9575 11.3647C15.1691 11.3647 15.3543 11.2898 15.5041 11.1399C16.2977 10.3464 16.9413 9.40302 17.435 8.31856C18.3608 6.26427 18.2506 4.4877 17.1 2.98886ZM15.4953 3.49582L15.4997 3.50023L15.5041 3.50464C16.6106 4.61555 16.787 6.00858 16.0243 7.67935C15.7246 8.34501 15.3543 8.94014 14.9267 9.47355L9.52642 4.07332C10.0554 3.6413 10.655 3.27541 11.3162 2.97564C12.9914 2.21299 14.3844 2.38933 15.4953 3.49582Z",
          fill: _vm.isActive ? "#343A60" : "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M8.40673 8.40671L6.77123 10.0422L8.95337 12.2243L10.5889 10.5888C10.7387 10.439 10.9195 10.364 11.1355 10.364C11.3471 10.364 11.5323 10.439 11.6821 10.5888C11.832 10.7387 11.907 10.9195 11.907 11.1355C11.907 11.3471 11.832 11.5322 11.6821 11.6821L10.0466 13.3176L11.1399 14.4109C11.2898 14.5608 11.3647 14.7415 11.3647 14.9575C11.3647 15.1735 11.2898 15.3543 11.1399 15.5042C10.3464 16.2977 9.40742 16.9413 8.32297 17.435C6.26868 18.3696 4.49211 18.2594 2.99327 17.1088L1.32251 18.7796C1.17262 18.9294 0.991879 19.0044 0.77587 19.0044C0.559861 19.0044 0.379118 18.9294 0.229234 18.7796C0.158701 18.709 0.101392 18.6253 0.0617169 18.5283C0.0176334 18.4269 0 18.3299 0 18.2285C0 18.1271 0.0176334 18.0257 0.0573086 17.9332C0.0969838 17.8406 0.154292 17.7568 0.224826 17.6819L1.89559 16.0111C0.745012 14.5123 0.634803 12.7357 1.56937 10.6814C2.06311 9.59696 2.70673 8.65798 3.50023 7.86448C3.65012 7.7146 3.83086 7.63965 4.04687 7.63965C4.25847 7.63965 4.44362 7.7146 4.5935 7.86448L5.68678 8.95775L7.31787 7.31784C7.46775 7.16796 7.64849 7.09302 7.8645 7.09302C8.0761 7.09302 8.26125 7.16796 8.41114 7.31784C8.56102 7.46773 8.63596 7.64847 8.63596 7.86448C8.63596 8.07608 8.56102 8.25682 8.40673 8.40671Z",
          fill: "#717DC6",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }